import React, { Suspense } from "react";
import { useLocalizedString } from "modules/client/localization";
import {
    MultipleChoiceStep,
    StepDefinition,
    StepDefinitionKey,
    StepRecord,
    TextEntryStep,
} from "modules/shared/content-types";
import { BaseActivityProps, SessionState } from "modules/shared/types";
import LoadingBlock from "modules/components/LoadingBlock";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules/client/application-state";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import InPersonNavigation from "modules/components/InPersonExperience/InPersonNavigation";
import { Container } from "react-bootstrap";

const TextEntryActivity: any = React.lazy(() => import("./InPersonTextEntryActivity"));
const MultipleChoiceActivity: any = React.lazy(() => import("./InPersonMultipleChoiceActivity"));

const renderActivity = <ContentType extends StepDefinitionKey>(
    currentStep: StepDefinition & StepRecord<ContentType>,
    noActivityFoundText: string,
) => {
    const baseProps: BaseActivityProps = {
        showRotateNavigator: () => null,
    };

    switch (currentStep.contentType) {
        case "textEntryStep":
            return <TextEntryActivity {...(currentStep as TextEntryStep)} {...baseProps} />;
        case "multipleChoiceStep":
            return <MultipleChoiceActivity {...(currentStep as MultipleChoiceStep)} {...baseProps} />;
        case "cardStackWithVisualStep":
        case "revealCardStep":
        case "dragAndDropStep":
        case "matchingStep":
        case "recallStep":
        case "editableCardStep":
        case "singleAssetStep":
        case "comparisonStep":
        case "videoStep":
        case "progressCheck":
        case "infoStep":
        case "exit":
            return <>{noActivityFoundText}</>;
        default:
            return <>{noActivityFoundText}</>;
    }
};

const InPersonActivites = () => {
    const localized = useLocalizedString();
    const state = useSelector(
        (state: ApplicationState): boolean | SessionState =>
            state.session.state === "READY" && state.session.effectiveState,
    );
    const currentStep = createGenericStepDefinitionSelector()(state as SessionState);
    const currentActivityType = currentStep?.contentType;

    const noActivityFoundText = localized("vdpActivity_notFound", currentActivityType);

    return (
        <Container className="d-flex flex-column">
            <div style={{ minHeight: "650px" }}>
                <Suspense fallback={<LoadingBlock />}>{renderActivity(currentStep, noActivityFoundText)}</Suspense>
            </div>

            <div className="mt-auto">
                <InPersonNavigation />
            </div>
        </Container>
    );
};

export default InPersonActivites;
