export enum ReportStatus {
    Init,
    Editing,
    InProgress,
    Complete,
    Error,
}

export type SessionBasicInfoRow = {
    session_uuid: string;
    experience_uuid: string;
    opens_at: string;
    closes_at: string;
    session_started_at: string;
    session_ended_at: string;
    version_hash: string;
    num_completed_participants: number;
};

export type MultipleChoiceBasicInfoRow = {
    experience_uuid: string;
    activity_hash: string;
    external_id: string; //activity ID
    question_prompt: string;
    possible_answer_external_id: string;
    is_correct: boolean;
    answer_body: string;
    num_answers: number;
};

export type MultipleChoiceBasicInfoAggregatedRow = {
    activity_id: string;
    activity_title: string;
    activity_prompt: string;
    answer_choice_entry_id: string;
    answer_choice_entry_text: string;
    is_answer_correct: boolean;
    answer_choice_rate: string;
};

export type ActivityBasicInfoRow = {
    session_uuid: string;
    experience_uuid: string;
    experience_hash: string;
    activity_hash: string;
    seconds_elapsed: string | number;
    content_type: string;
    external_id: string; //activity ID
    external_title: string; // activity title
};

export type ActivityBasicInfoAggregatedRow = {
    activity_id: string;
    activity_title: string;
    activity_version: string;
    average_time: number;
};

export type DragAndDropBasicInfoRow = {
    session_uuid: string;
    experience_uuid: string;
    activity_hash: string;
    external_id: string; // activity ID
    card_external_id: string;
    card_title: string;
    card_details: string;
    column_external_id: string;
    column_title: string;
    column_order: string;
};

export type DragAndDropBasicInfoAggregatedRow = {
    activity_id: string;
    activity_title: string;
    card_title: string;
    card_id: string;
    card_body: string;
    number_of_activities: number;
    average_column_order: number;
};

export type SessionBasicInfoReportData = {
    type: "SessionBasicInfo";
    data: SessionBasicInfoRow[];
};

export type MultipleChoiceBasicInfoReportData = {
    type: "MultipleChoiceBasicInfo";
    data: MultipleChoiceBasicInfoRow[];
};

export type MultipleChoiceBasicInfoAggregatedReportData = {
    type: "MultipleChoiceBasicInfoAggregated";
    data: MultipleChoiceBasicInfoAggregatedRow[];
    sessionCount: string;
};

export type ActivityBasicInfoReportData = {
    type: "ActivityBasicInfo";
    data: ActivityBasicInfoRow[];
};

export type ActivityBasicInfoAggregatedReportData = {
    type: "ActivityBasicInfoAggregated";
    data: ActivityBasicInfoAggregatedRow[];
    sessionCount: string;
};

export type DragAndDropBasicInfoReportData = {
    type: "DragAndDropBasicInfo";
    data: DragAndDropBasicInfoRow[];
};

export type DragAndDropBasicInfoAggregatedReportData = {
    type: "DragAndDropBasicInfoAggregated";
    data: DragAndDropBasicInfoAggregatedRow[];
    sessionCount: string;
};

type ReportDataWithoutSessionCount =
    | SessionBasicInfoReportData
    | ActivityBasicInfoReportData
    | MultipleChoiceBasicInfoReportData
    | DragAndDropBasicInfoReportData;

type ReportDataWithSessionCount =
    | ActivityBasicInfoAggregatedReportData
    | DragAndDropBasicInfoAggregatedReportData
    | MultipleChoiceBasicInfoAggregatedReportData;

export type ReportData = ReportDataWithoutSessionCount | ReportDataWithSessionCount;

export type ReportType = ReportData["type"];
type ReportFilterOptions<RType extends ReportType | null, TOptions> = {
    type: RType;
    options: TOptions;
    useSeedData?: boolean;
};

export type EmptyReportFilterOptions = ReportFilterOptions<null, null>;

export type SessionBasicInfoFilters = {
    startDate?: Date;
    endDate?: Date;
};

export type ActivityBasicInfoFilters = {
    startDate?: Date;
    endDate?: Date;
};

export type ActivityBasicInfoAggregatedFilters = {
    startDate?: Date;
    endDate?: Date;
};

export type MultipleChoiceBasicInfoFilters = {
    startDate?: Date;
    endDate?: Date;
    activityId?: string;
};

export type MultipleChoiceBasicInfoAggregatedFilters = {
    startDate?: Date;
    endDate?: Date;
    activityId?: string;
};

export type DragAndDropBasicInfoFilters = {
    startDate?: Date;
    endDate?: Date;
    activityId?: string;
};

export type DragAndDropBasicInfoAggregatedFilters = {
    startDate?: Date;
    endDate?: Date;
    activityId?: string;
};

export type SessionBasicInfoFilterOptions = ReportFilterOptions<"SessionBasicInfo", SessionBasicInfoFilters>;
export type MultipleChoiceBasicInfoFilterOptions = ReportFilterOptions<
    "MultipleChoiceBasicInfo",
    MultipleChoiceBasicInfoFilters
>;
export type MultipleChoiceBasicInfoAggregatedFilterOptions = ReportFilterOptions<
    "MultipleChoiceBasicInfoAggregated",
    MultipleChoiceBasicInfoAggregatedFilters
>;
export type ActivityBasicInfoFilterOptions = ReportFilterOptions<"ActivityBasicInfo", ActivityBasicInfoFilters>;
export type ActivityBasicInfoAggregatedFilterOptions = ReportFilterOptions<
    "ActivityBasicInfoAggregated",
    ActivityBasicInfoAggregatedFilters
>;
export type DragAndDropBasicInfoFilterOptions = ReportFilterOptions<
    "DragAndDropBasicInfo",
    DragAndDropBasicInfoFilters
>;

export type DragAndDropBasicInfoAggregatedFilterOptions = ReportFilterOptions<
    "DragAndDropBasicInfoAggregated",
    DragAndDropBasicInfoAggregatedFilters
>;

export type SelectedFilterState =
    | SessionBasicInfoFilterOptions
    | ActivityBasicInfoFilterOptions
    | ActivityBasicInfoAggregatedFilterOptions
    | MultipleChoiceBasicInfoFilterOptions
    | MultipleChoiceBasicInfoAggregatedFilterOptions
    | DragAndDropBasicInfoFilterOptions
    | DragAndDropBasicInfoAggregatedFilterOptions;

type ReportCompleteState = {
    status: ReportStatus.Complete;
    result: ReportData;
    filters: SelectedFilterState;
};

type ReportInProgressState = {
    status: ReportStatus.InProgress;
    filters: SelectedFilterState;
};

type ReportInitState = {
    status: ReportStatus.Init;
    filters: EmptyReportFilterOptions;
};

export type FieldErrors = {
    [fieldName: string]: string[];
};

export type ReportErrorState = {
    status: ReportStatus.Error;
    filters: SelectedFilterState | EmptyReportFilterOptions;
    errors: {
        message: string;
        fieldErrors: FieldErrors;
    };
};

export type ReportEditingState = {
    status: ReportStatus.Editing;
    filters: SelectedFilterState;
};

export type SessionReportState =
    | ReportInitState
    | ReportInProgressState
    | ReportCompleteState
    | ReportErrorState
    | ReportEditingState;
