import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrivacyPolicyBanner from "modules/components/PrivacyPolicyBanner/PrivacyPolicyBanner";
import { FieldValues, useForm } from "react-hook-form";
import { validateSession, FormData} from "./utils/sessionUtils";
import WorkShopIdForm from "modules/components/InPersonExperience/WorkShopIdForm";
import { useExperience } from "./ExperienceContext";

const InPersonExperience: React.FC = () => {
    const { experienceId } = useExperience();
    const [formValues, setFormValues] = useState({ workShopId: "", tableId: "" });
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    const navigate = useNavigate();

    const onSubmit = handleSubmit(async (data: FieldValues) => {
        const { workShopId, tableId } = data;
        setFormValues({
            workShopId: workShopId,
            tableId: tableId,
        });
        await handleValidateSession(workShopId, tableId);
    });

    const handleValidateSession = async (workShopId: string, tableId: string) => {
        const result = await validateSession(workShopId, tableId, setError, experienceId!);
        if (result?.joinUrl) {
            const path = new URL(result.joinUrl).pathname;
            navigate(path, { state: result.isSessionExists });
        }
    };

    return (
        <>
            <WorkShopIdForm register={register} onSubmit={onSubmit} errors={errors} formValues={formValues} />
            <PrivacyPolicyBanner />
        </>
    );
};

export default InPersonExperience;
