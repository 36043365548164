import React from "react";
import { Container, Card } from "react-bootstrap";
import { ExperienceProvider, useExperience } from "pages/inPerson/ExperienceContext";
type Props = Record<string, unknown>;

const LayoutContent: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    const { experienceName } = useExperience();

    return (
        <Container className="App d-flex flex-column vh-100">
            <Container className="py-4 d-flex justify-content-center align-items-center flex-grow-1">
                <Card className="shadow-sm d-flex flex-column" style={{ width: "430px", minHeight: "800px" }}>
                    <Card.Header className="bg-primary text-white text-center font-weight-bold">
                        {experienceName}
                    </Card.Header>
                    <Card.Body className="d-flex flex-column flex-grow-1">{children}</Card.Body>
                    <Card.Footer className="text-center text-muted mt-2">
                        Copyright @ Accenture {new Date().getFullYear()}
                    </Card.Footer>
                </Card>
            </Container>
        </Container>
    );
};

const InPersonLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
    return (
        <ExperienceProvider>
            <LayoutContent>{children}</LayoutContent>
        </ExperienceProvider>
    );
};

export default InPersonLayout;
