import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useProtectedFetch } from "modules/hooks";
import { useMsal } from "@azure/msal-react";
import FullPageLoading from "modules/components/FullPageLoading";
import Experience from "modules/database_types/experience";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules/client/application-state";
import { SessionState } from "modules/shared/types";

interface ExperienceContextProps {
    experienceName: string;
    experienceId: string;
}

const ExperienceContext = createContext<ExperienceContextProps | undefined>(undefined);

export const useExperience = () => {
    const context = useContext(ExperienceContext);
    if (!context) {
        throw new Error("useExperience must be used within an ExperienceProvider");
    }
    return context;
};

export const ExperienceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { instance: msalInstance } = useMsal();
    const getExperiencesOptions = React.useMemo(() => ({ method: "GET" }), []);
    const experiences_get = useProtectedFetch<Experience[]>(msalInstance, "/api/v1/experiences", getExperiencesOptions);
    const [experiences, setExperiences] = useState<Experience[]>([]);
    const [experienceId, setExperienceId] = useState<string>("");
    const [experienceName, setExperienceName] = useState<string>("");
    const [loadingExperiences, setLoadingExperiences] = useState<boolean>(true);

    const location = useLocation();
    const paramId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

    const state = useSelector(
        (state: ApplicationState): boolean | SessionState =>
            state.session.state === "READY" && state.session.effectiveState,
    ) as SessionState;

    useEffect(() => {
        if (!experienceId) {
            setExperienceId(paramId);
        }
    }, [experienceId, paramId]);

    useEffect(() => {
        if (experiences_get.state === "DONE" && experiences_get.data) {
            setExperiences(experiences_get.data);
            setLoadingExperiences(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experiences_get.state]);

    useEffect(() => {
        if (!loadingExperiences && experienceId) {
            const filterExp = experiences.find((experience) => experience.uuid === experienceId);
            if (filterExp) {
                setExperienceName(filterExp.experience_name);
            }
        }
    }, [loadingExperiences, experienceId, experiences]);

    useEffect(() => {
        const expId = state && state.sessionMetadata.experience.uuid;
        const expName = state && state.sessionMetadata.experience.experience_name;
        if (expId) {
            setExperienceId(expId);
        }
        if (expName) {
            setExperienceName(expName);
        }
    }, [state]);

    if (loadingExperiences) {
        return <FullPageLoading />;
    }

    return <ExperienceContext.Provider value={{ experienceName, experienceId }}>{children}</ExperienceContext.Provider>;
};
