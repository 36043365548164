import { yupResolver } from "@hookform/resolvers/yup";
import {
    addDays,
    addWeeks,
    addYears,
    differenceInCalendarDays,
    format,
    formatISO,
    isAfter,
    isBefore,
    isEqual,
    isValid,
    isWithinInterval,
    parseISO,
    set,
} from "date-fns";
import { formatInTimeZone, format as tzFormat } from "date-fns-tz";
import { range } from "lodash";
import { Action } from "modules/client/actions-types";
import { getAvailableLocales, LocaleOption, useDateFnsLocale, useLocalizedString } from "modules/client/localization";
import { createSessionsRequestBody } from "modules/client/utils";
import AdminContainer from "modules/components/AdminContainer";
import FullPageLoading from "modules/components/FullPageLoading";
import LanguageSelection from "modules/components/LanguageSelection";
import MaintenanceWindow from "modules/components/MaintenanceWindow";
import { ExperienceWithLocales } from "modules/database_types/experience";
import { useFetch } from "modules/hooks";
import { MaintenanceWindowResponse } from "modules/shared/types";
import React, { Dispatch, useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { bool, date, number, object } from "yup";
import GeneratedUrlDisplay from "./GeneratedUrlDisplay";
import "./GenerateUrls.scss";
import { Notification, Session } from "./types";
import ConfirmationDialogue from "modules/components/ConfirmationDialog";

type DateTimeInput = {
    dateValue: Date;
    hourValue: number;
    minuteValue: number;
};

type Inputs = {
    numSessions: number;
    opensOn: DateTimeInput;
    closesOn: DateTimeInput;
    languageCode: string;
    dataCollection: boolean;
};

const GenerateUrls: React.FC<Record<string, string | undefined>> = () => {
    // Generic object to store success/failure notifications
    const [notification, setNotification] = useState<Notification | null>(null);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [locales, setLocales] = useState<LocaleOption[]>([]);

    const localized = useLocalizedString();
    const dateFnLocale = useDateFnsLocale();
    const [sessionLanguage, setSessionLanguage] = useState("en-US");
    const dispatch = useDispatch<Dispatch<Action>>();

    const today = new Date();
    const today_eod = set(today, {
        hours: 23,
        minutes: 59,
        seconds: 59,
    });

    const { uuid } = useParams();

    const experience = useFetch<ExperienceWithLocales>(`/api/v1/experiences/${uuid}?locales=true`);
    const timeWindow = useFetch<MaintenanceWindowResponse>("/api/v1/maintenance-window");

    const defaultTimeWindowStart = addDays(Date.now(), 7);

    const [timeWindowStart, setTimeWindowStart] = useState<Date | null>(null);
    const [timeWindowEnd, setTimeWindowEnd] = useState<Date | null>(null);

    const [closesOnDate, setClosesOnDate] = useState<Date>(addDays(today_eod, 31));
    const client_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedTimeZone = formatInTimeZone(today, client_timezone, "zzz");
    const hour_select_range = range(0, 24);
    const minute_select_range = range(0, 60);

    const [experienceCloseDate, setExperienceCloseDate] = useState<Date>();
    const daysUntilExpiration = differenceInCalendarDays(closesOnDate, today);

    const [opensOnDate, setOpensOnDate] = useState<Date>(today);
    const daysUntilOpen = differenceInCalendarDays(opensOnDate, today);

    const [dataCollectionDefault, setDataCollectionDefault] = useState<boolean>(true);

    const [confirmUpdateDataCollectionDefault, setConfirmUpdateDataCollectionDefault] = useState(false);

    // helpers
    const getMaxDate = (): Date => {
        // if experience close date is set, use that as the max; otherwise
        return experienceCloseDate && isBefore(experienceCloseDate, addYears(today, 1))
            ? experienceCloseDate
            : addYears(today, 1);
    };
    const timeDigitToString = (digit: number): string => {
        if (digit >= 100 || digit < 0) {
            throw new Error("digit must be in the range [0, 100)");
        }
        const num_as_string = digit.toString();
        if (num_as_string.length === 1) {
            return `0${num_as_string}`;
        } else {
            return num_as_string;
        }
    };
    const hour_options = hour_select_range.map((digit) => {
        return (
            <option key={digit} value={digit}>
                {timeDigitToString(digit)}
            </option>
        );
    });
    const minute_options = minute_select_range.map((digit) => {
        return (
            <option key={digit} value={digit}>
                {timeDigitToString(digit)}
            </option>
        );
    });

    const UrlGenSchema = object().shape({
        numSessions: number()
            .typeError(localized("genUrl_numSessionsInputTypeError"))
            .max(1000)
            .moreThan(0)
            .label(localized("genUrl_numSessionsInputLabel")),
        opensOn: object().shape({
            dateValue: date()
                .min(addDays(new Date(), -1), localized("genUrl_closesOnError"))
                .max(getMaxDate(), localized("genUrl_experienceClosesOnError", format(getMaxDate(), "yyyy-MM-dd"))),
            hourValue: number().max(23).min(0),
            minuteValue: number().max(59).min(0),
        }),
        closesOn: object().shape({
            dateValue: date()
                .min(addDays(new Date(), -1), localized("genUrl_closesOnError"))
                .max(getMaxDate(), localized("genUrl_experienceClosesOnError", format(getMaxDate(), "yyyy-MM-dd"))),
            hourValue: number().max(23).min(0),
            minuteValue: number().max(59).min(0),
        }),
        dataCollection: bool(),
    });

    // Form Component
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<Inputs>({
        resolver: yupResolver(UrlGenSchema),
        defaultValues: {
            languageCode: "en-US",
            numSessions: 1,
            opensOn: {
                // we don't set default date value default here bc typescript gets mad
                hourValue: today.getHours(),
                minuteValue: today.getMinutes(),
            },
            closesOn: {
                // we don't set default date value default here bc typescript gets mad
                hourValue: 23,
                minuteValue: 59,
            },
            dataCollection: true,
        },
    });

    const confirmDataCollectionValueText = useMemo(
        () =>
            dataCollectionDefault
                ? localized("confirmDialog_dataCollectionDefault_body_enable")
                : localized("confirmDialog_dataCollectionDefault_body_disable"),
        [dataCollectionDefault, localized],
    );

    useEffect(() => {
        // check to see if default date should be set and the input updated
        // typescript gets confused here thinking the closesOn.dateValue is a date when it's a string
        // force it to be recognized as a string
        let currentClosesOnString = getValues("closesOn.dateValue") as unknown as string;
        const currentClosesOnHour = timeDigitToString(getValues("closesOn.hourValue"));
        const currentClosesOnMinute = timeDigitToString(getValues("closesOn.minuteValue"));
        currentClosesOnString = `${currentClosesOnString} ${currentClosesOnHour}:${currentClosesOnMinute}`;
        const currentClosesOn = parseISO(currentClosesOnString);

        let currentOpensOnString = getValues("opensOn.dateValue") as unknown as string;
        const currentOpensOnHour = timeDigitToString(getValues("opensOn.hourValue"));
        const currentOpensOnMinute = timeDigitToString(getValues("opensOn.minuteValue"));
        currentOpensOnString = `${currentOpensOnString} ${currentOpensOnHour}:${currentOpensOnMinute}`;
        const currentOpensOn = parseISO(currentOpensOnString);

        let nextCurrentClosesOn = currentClosesOn;
        const now = new Date();

        if (experience.state === "DONE") {
            const experienceLocales = experience.data.locales;
            const availableLocales = getAvailableLocales(experienceLocales);
            setLocales(availableLocales);
            const experienceEndDate = new Date(experience.data.scheduling_available_through);
            setExperienceCloseDate(experienceEndDate);
            setValue("dataCollection", dataCollectionDefault);

            if (isAfter(nextCurrentClosesOn, experienceEndDate)) {
                nextCurrentClosesOn = experienceEndDate;
            }
        }

        if (timeWindow.state === "DONE") {
            const { start, end } = timeWindow.data;
            const startTime = start ? parseISO(start) : null;
            const endTime = end ? parseISO(end) : null;
            setTimeWindowStart(startTime);
            setTimeWindowEnd(endTime);

            if (
                startTime &&
                endTime &&
                currentOpensOn <= nextCurrentClosesOn &&
                (isWithinInterval(startTime, { start: currentOpensOn, end: nextCurrentClosesOn }) ||
                    isWithinInterval(currentOpensOn, { start: startTime, end: endTime }))
            ) {
                nextCurrentClosesOn = isBefore(now, startTime) ? startTime : addWeeks(startTime, 1);
            } else if (startTime && isBefore(now, startTime) && isBefore(currentOpensOn, startTime)) {
                nextCurrentClosesOn = startTime;
            } else if (startTime && endTime && isBefore(endTime, currentOpensOn)) {
                nextCurrentClosesOn = addWeeks(startTime, 1);
            }
        }

        if (nextCurrentClosesOn !== currentClosesOn) {
            setValue("closesOn.dateValue", format(nextCurrentClosesOn, "yyyy-MM-dd") as unknown as Date);
            setValue("closesOn.hourValue", nextCurrentClosesOn.getHours());
            setValue("closesOn.minuteValue", nextCurrentClosesOn.getMinutes());
            setClosesOnDate(nextCurrentClosesOn);
        }
    }, [experience, timeWindow, getValues, setValue]);

    const onClientLanguage = (languageName: string, languageCode: string) => {
        dispatch({
            type: "CLIENT_LANGUAGE_CHANGED",
            languageCode: languageCode,
        });
    };

    const onSessionLanguage = (languageCode: string) => {
        setSessionLanguage(languageCode);
    };

    const onOpensAtDateChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newDate = parseISO(e.currentTarget.value);
        if (!isEqual(newDate, opensOnDate)) {
            setValue("opensOn.hourValue", 0);
            setValue("opensOn.minuteValue", 0);
            setOpensOnDate(newDate);
        }
    };

    // Form submit handles
    const onSubmit = handleSubmit(async (data) => {
        try {
            const opensDateTimeValue = set(data.opensOn.dateValue, {
                hours: data.opensOn.hourValue ?? 23,
                minutes: data.opensOn.minuteValue ?? 59,
                seconds: 0,
            });
            const closesDateTimeValue = set(data.closesOn.dateValue, {
                hours: data.closesOn.hourValue ?? 23,
                minutes: data.closesOn.minuteValue ?? 59,
                seconds: 0,
            });
            const response = await fetch(`/api/v1/experiences/${uuid}/sessions`, {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: createSessionsRequestBody({
                    number: data.numSessions,
                    closes_at: formatISO(closesDateTimeValue),
                    opens_at: formatISO(opensDateTimeValue),
                    language_code: data.languageCode,
                    data_collection: data.dataCollection,
                }),
            });
            if (response.ok) {
                const body = await response.json();
                setSessions(body);
                setNotification({
                    message:
                        data.numSessions == 1
                            ? localized("genUrl_notifSuccessSingle", data.numSessions)
                            : localized("genUrl_notifSuccessMultiple", data.numSessions),
                    type: "success",
                });
            } else {
                const body = await response.json();
                if (
                    response.status === 422 &&
                    body.type === "maintenance_window_error" &&
                    timeWindowStart &&
                    timeWindowEnd
                ) {
                    const overlappingWindow = parseISO(body.window_start);
                    const message = localized(
                        "maintenanceError_overlap",
                        format(overlappingWindow, "eeee, MMMM d", { locale: dateFnLocale }),
                        tzFormat(timeWindowStart, "eeee, haaa z", { locale: dateFnLocale }),
                        tzFormat(timeWindowEnd, "eeee, haaa z", { locale: dateFnLocale }),
                    );
                    setNotification({ message: message, type: "danger" });
                } else {
                    setNotification({
                        message: response.status === 422 ? body.message : localized("genUrl_notifErrorGenerating"),
                        type: "danger",
                    });
                }
            }
        } catch (e) {
            setNotification({
                message: localized("genUrl_notifErrorProcessing"),
                type: "danger",
            });
        }
    });

    if (experience.state === "LOADING") {
        return <FullPageLoading />;
    } else if (experience.state === "DONE") {
        if (isBefore(today, new Date(experience.data.scheduling_available_through))) {
            return (
                <AdminContainer>
                    <Row className="language-container">
                        <Col md={2} className="language-column">
                            <LanguageSelection
                                title="Language"
                                languages={getAvailableLocales()}
                                onSelect={onClientLanguage}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>{localized("genUrl_title", experience.data.company_name)}</h1>
                            <h5>{localized("genUrl_experience", experience.data.experience_name)}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{localized("genUrl_description")}</p>
                        </Col>
                        <MaintenanceWindow
                            timeWindowStart={timeWindowStart}
                            timeWindowEnd={timeWindowEnd}
                            locale={dateFnLocale}
                        />
                    </Row>

                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {notification && (
                                <Alert dismissible onClose={() => setNotification(null)} variant={notification.type}>
                                    {notification.message}
                                </Alert>
                            )}
                        </Col>
                    </Row>

                    {sessions.length === 0 ? (
                        /** we want the datepicker to disable past dates but show our own error if needed */
                        <Form onSubmit={onSubmit} noValidate data-testid="generate-session-form">
                            <Row className="generateSessions">
                                <Col>
                                    <Form.Group controlId="formNumSessions">
                                        <Form.Label>{localized("genUrl_numSessions")}</Form.Label>
                                        <Form.Control isInvalid={!!errors.numSessions} {...register("numSessions")} />
                                        {errors.numSessions && (
                                            <Form.Text as="span" className="text-danger">
                                                {errors.numSessions.message}
                                            </Form.Text>
                                        )}
                                        {/* @todo: let me be uncontroller */}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formLanguageCode">
                                        <Form.Label>{localized("genUrl_sessionLanguageLabel")}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            {...register("languageCode", {
                                                onChange: (e) => onSessionLanguage(e.currentTarget.value),
                                            })}
                                        >
                                            {locales.map(({ name, value }) => (
                                                <option value={value} key={value}>
                                                    {name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="generateSessions">
                                <Col>
                                    <Form.Group controlId="formOpensOn">
                                        <Form.Label>{localized("genUrl_sessionsOpenAtLabel")}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            defaultValue={format(today, "yyyy-MM-dd")}
                                            {...register("opensOn.dateValue", {
                                                onChange: onOpensAtDateChange,
                                                min: format(today, "yyyy-MM-dd"),
                                            })}
                                            isInvalid={isValid(opensOnDate) && !!errors.opensOn?.dateValue}
                                        />
                                        <InputGroup>
                                            <Form.Control
                                                as="select"
                                                min={0}
                                                max={23}
                                                aria-label="Hour of the Opens at Date"
                                                isInvalid={!!errors.opensOn?.hourValue}
                                                {...register("opensOn.hourValue")}
                                            >
                                                {hour_options}
                                            </Form.Control>
                                            <InputGroup.Text>:</InputGroup.Text>
                                            <Form.Control
                                                as="select"
                                                min={0}
                                                max={59}
                                                aria-label="Minute of the Opens at Date"
                                                isInvalid={!!errors.opensOn?.minuteValue}
                                                {...register("opensOn.minuteValue")}
                                            >
                                                {minute_options}
                                            </Form.Control>
                                            <InputGroup.Text>{formattedTimeZone}</InputGroup.Text>
                                        </InputGroup>
                                        {/* do not show "invalid date" error when datepicker is cleared out */}
                                        {isValid(opensOnDate) && errors.opensOn?.dateValue && (
                                            <Form.Text as="div" className="text-danger">
                                                {errors.opensOn.dateValue?.message}
                                            </Form.Text>
                                        )}
                                        {isValid(opensOnDate) && (
                                            <Form.Text as="div">
                                                {daysUntilOpen <= 0
                                                    ? localized("genUrl_sessionsOpenToday")
                                                    : daysUntilOpen === 1
                                                      ? localized("genUrl_sessionsOpenOneDay", daysUntilOpen)
                                                      : localized("genUrl_sessionsOpenDays", daysUntilOpen)}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formClosesOn">
                                        <Form.Label>{localized("genUrl_sessionsExpirationLabel")}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            data-testid="closesOnDate"
                                            defaultValue={format(defaultTimeWindowStart, "yyyy-MM-dd", {
                                                locale: dateFnLocale,
                                            })}
                                            {...register("closesOn.dateValue", {
                                                onChange: (e) => setClosesOnDate(parseISO(e.currentTarget.value)),
                                                min: format(today, "yyyy-MM-dd"),
                                            })}
                                            isInvalid={isValid(closesOnDate) && !!errors.closesOn?.dateValue}
                                        />
                                        <InputGroup>
                                            <Form.Control
                                                as="select"
                                                defaultValue={0}
                                                min={0}
                                                max={23}
                                                data-testid="closesOnHour"
                                                aria-label="Hour of the Closes at Date"
                                                isInvalid={!!errors.closesOn?.hourValue}
                                                {...register("closesOn.hourValue")}
                                            >
                                                {hour_options}
                                            </Form.Control>
                                            <InputGroup.Text>:</InputGroup.Text>
                                            <Form.Control
                                                as="select"
                                                min={0}
                                                max={59}
                                                placeholder="MM"
                                                aria-label="Minute of the Closes at Date"
                                                isInvalid={!!errors.closesOn?.minuteValue}
                                                {...register("closesOn.minuteValue")}
                                            >
                                                {minute_options}
                                            </Form.Control>
                                            <InputGroup.Text>{formattedTimeZone}</InputGroup.Text>
                                        </InputGroup>
                                        {/* do not show "invalid date" error when datepicker is cleared out */}
                                        {isValid(closesOnDate) && errors.closesOn?.dateValue && (
                                            <Form.Text as="div" className="text-danger">
                                                {errors.closesOn.dateValue?.message}
                                            </Form.Text>
                                        )}
                                        {isValid(closesOnDate) && daysUntilExpiration >= 0 && (
                                            <Form.Text as="div">
                                                {daysUntilExpiration === 0
                                                    ? localized("genUrl_sessionsExpireToday")
                                                    : daysUntilExpiration === 1
                                                      ? localized("genUrl_sessionsExpireInOneDay", daysUntilExpiration)
                                                      : localized("genUrl_sessionsExpireInDays", daysUntilExpiration)}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button type="submit" data-testid="generate-session-submit">
                                        {localized("genUrl_generate")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="generateSessions">
                                <Col xs lg={4}>
                                    <Form.Group controlId="formCollectData">
                                        <Form.Label>{localized("genUrl_dataCollectionLabel")}</Form.Label>
                                        <Form.Switch
                                            data-testid="formCollectData"
                                            defaultChecked={dataCollectionDefault}
                                            {...register("dataCollection")}
                                            onChange={() => {
                                                setConfirmUpdateDataCollectionDefault(true);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Text as="div">{localized("genUrl_dataCollectionDescription")}</Form.Text>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <GeneratedUrlDisplay
                            experience={experience.data}
                            sessions={sessions}
                            sessionLanguage={sessionLanguage}
                        />
                    )}
                    {confirmUpdateDataCollectionDefault && (
                        <ConfirmationDialogue
                            title={localized("confirmDialog_dataCollectionDefault_title")}
                            body={localized(
                                dataCollectionDefault
                                    ? "confirmDialog_dataCollection_off"
                                    : "confirmDialog_dataCollection_on",
                                confirmDataCollectionValueText,
                                experience.data.experience_name,
                            )}
                            onCloseCb={() => {
                                setConfirmUpdateDataCollectionDefault(false);
                                setValue("dataCollection", dataCollectionDefault);
                            }}
                            action={function (): void {
                                setDataCollectionDefault(!dataCollectionDefault);
                                setValue("dataCollection", !dataCollectionDefault);
                                setConfirmUpdateDataCollectionDefault(false);
                            }}
                        />
                    )}
                </AdminContainer>
            );
        } else {
            return <> {localized("genUrl_generate")}</>;
        }
    }

    return <>{localized("genUrl_error")}</>;
};

export default GenerateUrls;
