import {
    ADD_INPERSON_PARTICIPANTS_TO_STATE,
    IN_PERSON_GOTO_STEP,
    IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED,
    IN_PERSON_MULTIPLE_CHOICE_SUBMITTED,
    IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED,
    IN_PERSON_TEXT_ENTRY_SUBMITTED,
} from "modules/shared/activity/InPersonActivities/reducers";
import { Action } from "./actions-types";
import { ApplicationState, createDefaultApplicationState } from "./application-state";
import addClientEventToUncommitted from "./reducers/addClientEventToUncommitted";
import addParticipantMessage from "./reducers/addParticipantMessage";
import clientLanguageChanged from "./reducers/clientLanguageChanged";
import connectToInPersonSession from "./reducers/connectToInPersonSession";
import connectToSessionStarted from "./reducers/connectToSessionStarted";
import connectToSessionWaitingForInitialState from "./reducers/connectToSessionWaitingForInitialState";
import disconnectFromSession from "./reducers/disconnectFromSession";
import mapBoundsChanged from "./reducers/mapBoundsChanged";
import participantMessageDelete from "./reducers/participantMessageDelete";
import recordDebugData from "./reducers/recordDebugData";
import reincarnationCompleted from "./reducers/reincarnationCompleted";
import reincarnationRequested from "./reducers/reincarnationRequested";

import receiveSessionReportError from "./reducers/session-report/receiveSessionReportError";
import receiveSessionReportSuccess from "./reducers/session-report/receiveSessionReportSuccess";
import sendSessionReportRequest from "./reducers/session-report/sendSessionReportRequest";
import setEditingReportFilters from "./reducers/session-report/setEditingReportFilters";

import socketReconnect from "./reducers/socketReconnect";
import updateContentfulManagement from "./reducers/updateContentfulManagement";
import updateIsIdle from "./reducers/updateIsIdle";
import updateLastActiveTime from "./reducers/updateLastActiveTime";
import waitForResolution from "./reducers/waitForResolution";
import websocketError from "./reducers/websocketError";
import websocketMessageReceived from "./reducers/websocketMessageReceived";

export function reducer(state: ApplicationState = createDefaultApplicationState(), payload: Action): ApplicationState {
    try {
        switch (payload.type) {
            case "CONNECT_TO_SESSION_STARTED":
                return connectToSessionStarted(state, payload);
            case "CONNECT_TO_SESSION_WAITING_FOR_INITIAL_STATE":
                return connectToSessionWaitingForInitialState(state, payload);
            case "WEBSOCKET_MESSAGE_RECEIVED":
                return websocketMessageReceived(state, payload);
            case "ADD_TO_UNCOMMITTED":
                return addClientEventToUncommitted(state, payload);
            case "WAIT_FOR_RESOLUTION":
                return waitForResolution(state, payload);

            case "WEBSOCKET_ERROR":
                return websocketError(state, payload);
            case "SOCKET_RECONNECT":
                return socketReconnect(state, payload);
            case "REINCARNATION_REQUESTED":
                return reincarnationRequested(state);
            case "REINCARNATION_COMPLETED":
                return reincarnationCompleted(state);

            case "DISCONNECT_FROM_SESSION":
                return disconnectFromSession(state);

            case "UPDATE_LAST_ACTIVE_TIME":
                return updateLastActiveTime(state);
            case "SET_PARTICIPANT_IDLE":
                return updateIsIdle(state, payload);

            case "MAP_BOUNDS_CHANGED":
                return mapBoundsChanged(state, payload);

            case "CLIENT_LANGUAGE_CHANGED":
                return clientLanguageChanged(state, payload);

            case "PARTICIPANT_MESSAGE_RECEIVED":
                return addParticipantMessage(state, payload);

            case "PARTICIPANT_MESSAGE_DELETE":
                return participantMessageDelete(state, payload);

            case "UPDATE_CONTENTFUL_MANAGEMENT":
                return updateContentfulManagement(state, payload);

            // Session Report
            case "SEND_SESSION_REPORT_REQUEST":
                return sendSessionReportRequest(state, payload);

            case "EDITING_SESSION_REPORT_FILTERS":
                return setEditingReportFilters(state, payload);

            case "RECEIVE_SESSION_REPORT_SUCCESS":
                return receiveSessionReportSuccess(state, payload);

            case "RECEIVE_SESSION_REPORT_ERROR":
                return receiveSessionReportError(state, payload);

            case "DEBUG":
                return recordDebugData(state, payload);

            // in person data collection reducers
            case "ADD_INPERSON_PARTICIPANTS_TO_STATE":
                return ADD_INPERSON_PARTICIPANTS_TO_STATE(state, payload);
            case "CONNECT_TO_IN_PERSON_SESSION":
                return connectToInPersonSession(state, payload);
            case "IN_PERSON_GOTO_STEP":
                return IN_PERSON_GOTO_STEP(state, payload);
            case "IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED":
                return IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED(state, payload);
            case "IN_PERSON_TEXT_ENTRY_SUBMITTED":
                return IN_PERSON_TEXT_ENTRY_SUBMITTED(state, payload);
            case "IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED":
                return IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED(state, payload);
            case "IN_PERSON_MULTIPLE_CHOICE_SUBMITTED":
                return IN_PERSON_MULTIPLE_CHOICE_SUBMITTED(state, payload);
        }

        return state;
    } catch (e) {
        console.error("Error applying payload", payload, e);
        return state;
    }
}
