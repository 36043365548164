import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useExperience } from "pages/inPerson/ExperienceContext";

const ActionSelectorForm = () => {
    const { experienceId } = useExperience();
    const navigate = useNavigate();
    const { sessionId } = useParams();
    const activityHandler = () => {
        navigate(`/client/inPersonSession/${sessionId}`);
    };

    const returnHome = () => {
        navigate(`/client/inPerson/${experienceId}`);
    };

    return (
        <>
            <Card.Title as="h3" className="text-center m-3">
                Select an Action:
            </Card.Title>
            <Card.Text className="text-center m-3">You have selected an existing session</Card.Text>
            <Card.Body>
                <div className="d-flex justify-content-center mt-3">
                    <Button variant="primary" type="submit" onClick={activityHandler}>
                        Continue Session
                    </Button>
                </div>
                <p className="text-center m-3">OR</p>
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <Button variant="primary" type="submit" onClick={returnHome}>
                        Return to Home
                    </Button>
                </div>
            </Card.Body>
        </>
    );
};

export default ActionSelectorForm;
